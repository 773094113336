import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
// import { ReactDialogBox } from "react-js-dialog-box";

import SimpleReactValidator from "simple-react-validator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import MainLayout from "../layouts/MainLayout";

import Seo from "../components/common/SEO";

import PageHeader from "../components/common/page-header";
import RequestSuccessModal from "../components/features/modal/RequestSuccessModal";
import { graphql } from "gatsby";

const REQUEST_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};

function RequestProduct({ data }) {
  //hooks
  const { register, handleSubmit } = useForm();
  const validator = useRef(new SimpleReactValidator());
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [keywords, setkeywords] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState("");
  const [model, setModel] = useState("");
  const [date, setDate] = useState("");
  const [pCategory, setPCategory] = useState(null);
  const categories = data.allCategories.nodes.filter((c) => c._id !== "1");

  // form validation
  const submitForm = async () => {
    //newCReq is an object to get the wanted schema

    if (typeof window !== "undefined") {
      if (validator.current.allValid()) {
        setIsLoading(true);
        const url = `${REQUEST_BASE_URL}`;
        const method = "post";
        const headers = REQUEST_HEADERS;
        try {
          const response = await fetch(url, {
            method,
            headers,
            body: JSON.stringify({
              email,
              firstName,
              lastName,
              contact: phone,
              address,
              productRequest: {
                isProductRequest: true,
                category: pCategory,
                modelNumber: model,
                keywords: keywords,
              },
              date: date,
            }),
          });

          if (response.ok) {
            openBox();
            openBox();
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setModel("");
            setAddress("");
            setPCategory(null);
            setDate("");
            setkeywords([]);
            validator.current.hideMessageFor("firstName");
            validator.current.hideMessageFor("lastName");
            validator.current.hideMessageFor("email");
            validator.current.hideMessageFor("phone");
          } else {
            const error = await response.json();
            console.log(error);
            setErrors(error.message);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        validator.current.showMessages();

        // rerender to show messages for the first time
      }
    }
    setIsLoading(false);
  };
  //dialog box handler
  const openBox = () => {
    setIsOpen(true);
  };
  const closeBox = () => {
    setIsOpen(false);
  };

  return (
    <MainLayout>
      <RequestSuccessModal show={isOpen} removeNewsletterMdoal={closeBox} />
      <LoadingOverlay>
        <Loader text="Processing ..." loading={isLoading} />

        <Seo title="Owls - Request a product" />
        <div className="main">
          <h1 className="d-none">Owls - Request a product</h1>
          <PageHeader title="Request a product" />

          {/* <Breadcrumb title="productrequest" adClass="border-0 mb-0" /> */}

          <div className="page-content pb-0">
            <div className="container">
              <hr className="mt-3 mb-5 mt-md-1" />
              <div className="row justify-content-center mb-2">
                <div className="col-lg-7  ">
                  <div className="card bg-primary">
                    <div className="card-body  text-center">
                      <p style={{fontSize: "2rem"}} className="text-white pt-2">
                        Can't find the product you are looking for?
                      </p>
                    </div>
                    <div
                      style={{ backgroundColor: "#000" }}
                      className="card-body text-white text-center"
                    >
                      Are you having trouble finding the products you need? We
                      can help! Here at OWLS614, we make it our mission to help
                      you find exactly what you’re looking for at the best
                      prices possible! If you’re on the hunt for a specific item
                      that we do not have in stock, all you need to do is submit
                      a request and let us know! We will reach out to you as
                      soon as we have a matching product in stock.
                    </div>
                  </div>
                </div>
              </div>

              <div className="touch-container row justify-content-center">
                <div className="col-md-9 col-lg-7">
                  {errors && (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-danger w-100" role="alert">
                          {typeof errors === "string" ? (
                            errors
                          ) : (
                            <ul>
                              {errors.map((err, i) => (
                                <li
                                  key={"error" + i}
                                  style={{ display: "list-item" }}
                                >
                                  {err.msg}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <form
                    action="#"
                    className="contact-form mb-2"
                    onSubmit={handleSubmit(submitForm)}
                  >
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="firstName" className="sr-only">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="First name *"
                          value={firstName}
                          {...register("firstName", {
                            onChange: (e) => setFirstName(e.target.value),
                          })}
                          onBlur={() =>
                            validator.current.showMessageFor("firstName")
                          }
                        />
                        {validator.current.message(
                          "firstName",
                          firstName,
                          "required|alpha_space",
                          { className: "text-danger" }
                        )}
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="lastName" className="sr-only">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="Last name *"
                          value={lastName}
                          {...register("lastName", {
                            onChange: (e) => setLastName(e.target.value),
                          })}
                          onBlur={() =>
                            validator.current.showMessageFor("lastName")
                          }
                        />
                        {validator.current.message(
                          "lastName",
                          lastName,
                          "required|alpha_space",
                          { className: "text-danger" }
                        )}
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="cphone" className="sr-only">
                          Phone
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="cphone"
                          defaultValue={phone}
                          placeholder="Phone"
                          {...register("contact", {
                            onChange: (e) => setPhone(e.target.value),
                          })}
                          onBlur={() =>
                            validator.current.showMessageFor("phone")
                          }
                        />
                        {validator.current.message("phone", phone, "phone", {
                          className: "text-danger",
                        })}
                      </div>
                    </div>
                    <label htmlFor="cemail" className="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      value={email}
                      placeholder="Email *"
                      {...register("email", {
                        onChange: (e) => setEmail(e.target.value),
                      })}
                      onBlur={() => validator.current.showMessageFor("email")}
                    />
                    {validator.current.message(
                      "email",
                      email,
                      "required|email",
                      {
                        className: "text-danger",
                      }
                    )}
                  
                    <input
                      type="address"
                      className="form-control"
                      aria-label="Address"
                      id="address"
                      value={address}
                      placeholder="Address"
                      {...register("address", {
                        onChange: (e) => setAddress(e.target.value),
                      })}
                    />
                    {/* <label htmlFor="ckeywords" className="sr-only">
                      Article
                    </label> */}
                    {/* <ReactTags
                      maxLength={20}
                      tags={keywords}
                      suggestions={suggestions}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      delimiters={delimiters}
                      id="ckeywords"
                      placeholder="describe the product in few words, (ex: white kitchen cabinet, brown vanity 32)"
                      classNames={{
                        tagInputField: "form-control",
                      }}
                    /> */}
                    <label htmlFor="keywords" className="sr-only">
                      keywords#
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="keywords"
                      value={keywords}
                      placeholder="describe the product in few words, (ex: white kitchen cabinet, brown vanity 32)"
                      {...register("keywords", {
                        onChange: (e) => setkeywords(e.target.value),
                      })}
                    />
                    <label htmlFor="model" className="sr-only">
                      Model#
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="model"
                      value={model}
                      placeholder="Looking for specific model? type here"
                      {...register("modelNumber", {
                        onChange: (e) => setModel(e.target.value),
                      })}
                    />
                    <p className="pl-2 text-black text-small">
                      What category best fit your products? choose from below
                    </p>
                    <select
                      className="form-control form-select"
                      aria-label="Choose a category"
                      defaultValue={pCategory}
                      // value={pCategory}
                      {...register("category", {
                        onChange: (e) => {
                          setPCategory(e.target.value);
                        },
                      })}
                    >
                      <option style={{color: "black"}}  key={"cat-" + -1} value={''}>
                        Choose category
                      </option>
                      {categories.map((category, index) => {
                        return (
                          <option style={{color: "black"}} key={"cat-" + index} value={category._id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                    <p className="pl-2 text-black text-small">
                      Do you need this by specific date? if yes enter below
                    </p>

                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={date}
                      placeholder="Date"
                      {...register("date", {
                        onChange: (e) => setDate(e.target.value),
                      })}
                    />
                    <div className="text-center">
                      <button
                        type="submit"
                        // onClick={submitForm}
                        className="btn btn-outline-primary-2 btn-minwidth-sm"
                      >
                        <span>SUBMIT REQUEST</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </MainLayout>
  );
}

export default React.memo(RequestProduct);
export const query = graphql`{
    allCategories {
      nodes{
      _id
      name
      itemCount
    }
    }
}
`;
