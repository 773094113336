import React, { useState, useEffect } from "react";
import Modal from "react-modal";

// import { isIEBrowser } from '../../../utils';

const customStyles = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "10000",
  },
};

function NewsletterModal(props) {
  const { show, removeNewsletterMdoal } = props;
  const [open, setOpen] = useState(show);

  useEffect(() => {
    Modal.setAppElement("#___gatsby");
    setOpen(show);
  }, [show]);

  function closeModal(e) {
    removeNewsletterMdoal();
    setOpen(false);

    document
      .getElementById("newsletter-popup-form")
      .classList.remove("ReactModal__Content--after-open");
  }

  return (
    <Modal
      isOpen={open}
      // appElement={document.getElementById('body')}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      shouldFocusAfterRender={false}
      contentLabel="Newsletter Modal"
      className="container newsletter-popup-container"
      id="newsletter-popup-form"
    >
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row no-gutters bg-white newsletter-popup-content">
            <div className="col-xl-12-12col col-lg-12 banner-content-wrap">
              <div className="banner-content text-center">
                {/* <img
                  src={`/assets/images/popup/newsletter/logo.png`}
                  alt="logo"
                  className="logo"
                  width="60"
                  height="15"
                /> */}
                <h2 className="banner-title ">
                  {/* get{" "} */}
                  <span className="text-success">
                    Success
                    <span
                      className="text-success"
                      style={{ fontWeight: "400" }}
                    >
                      <span role="img" aria-label="Success-icon">✅</span>
                    </span>
                  </span>{" "}
                  !
                </h2>
                <p>
                  Thanks! we will notify you when we get the products in stock
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={closeModal}
      >
        <span>×</span>
      </button>
    </Modal>
  );
}

export default NewsletterModal;
